<template>
  <v-card hover style="border-radius: 0.5rem;" outlined @click="expanded = !expanded">
    <v-card-title class="justify-center">
      {{ title }}
    </v-card-title>
    <v-expand-transition>
      <div v-show="expanded">
        <v-divider></v-divider>
        <v-card-text>
          <ol>
            <li
              v-for="(step, i) in steps"
              :key="i"
            >
            <span v-html="step"/>
            </li>
          </ol>
        </v-card-text>
      </div>
    </v-expand-transition>
      <v-divider></v-divider>
    <v-card-text class="text-center" >Click to {{ expanded ? 'hide' : 'expand' }}</v-card-text>
  </v-card>
</template>

<script>
export default {
  mixins: [],
  props: {
    title: String,
    steps: Array
  },
  data() {
    return {
      expanded: false
    }
  },
  components: {
  },
  computed: {
  },
  methods: {
  }
};
</script>