<template>
  <v-container fluid>
    <Header 
      title="Quck Start"
    ></Header>
    <v-row dense>
      <v-col cols="12" sm="4" v-for="(quickstart, i) in quickstarts" :key="i">
        <QuickStartCard
          :title="quickstart.title"
          :steps="quickstart.steps"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Header from "@/components/Header.vue"
import QuickStartCard from "@/components/support/QuickStartCard.vue"
export default {
  mixins: [],
  data() {
    return {
      quickstarts: [
        {
          title: 'Create Products',
          steps: [
            'Navigate to products page.',
            'Click the + button in the top right.',
            'Enter Product Name, Brand and Description and continue<ul><li>Description is not required</li></ul>',
            'Enter a Sku and Barcode<ul><li>Sku is required</li><li>If Variant product is selected, Name is also required</li></ul>',
            'Attach any images you would like to the product and press save.<ul><li>Additional images can be added later</li></ul>',
            'You will be directed to your newly created product.',
          ]
        },
        {
          title: 'Sync Products With Website',
          steps: [
            'Navigate to websites page.',
            'Select the website you would like to sync.',
            'Click the Sync button in the top right.',
            'Your website will begin syncing and you will recieve a notification once complete.'
          ]
        },
        {
          title: 'Publish Products To Website',
          steps: [
            'Navigate to the product you would like to publish.',
            'Select the publish button in the top right.',
            'Select which variants you would like to publish and continue.<ul><li>By default all variants are selected.</li></ul>',
            'Select which websites you would like to publish to and continue.',
            'Select the publish type and any advanced options, then confirm.<ul><li>By default all fields will be selected.</li></ul>',
          ]
        },
        {
          title: 'Create Brands',
          steps: [
            'Navigate to brands page.',
            'Click the + button in the top right.',
            'Enter a name and attach an image then press confirm.',
          ]
        },
        {
          title: 'Import Products',
          steps: [
            'Navigate to the Import/Export page.',
            'Download the Sample Create File.',
            'Fill in the file with your own data.<ul><li>Rquired fields can be found on the Import/Export page.</li></ul>',
            'Upload your file into the dropzone and select upload.',
            'Once your products are done importing you will recieved a notification.<ul><li>To view results navigate to the notification and download the results file.</li></ul>',
          ]
        },
        {
          title: 'Sync Individual Product',
          steps: [
            'Navigate to the product you would like to sync.',
            'Open Website Specific Options and select the website you would like to sync with.',
            'On the left hand side of the page select the sync button to sync your product with the website tab you are on..<ul><li>This only connects the product with the website if it exists on the website.</li></ul>',
          ]
        }
      ],
    };
  },
  components: {
    Header,
    QuickStartCard
  },
  computed: {
  },
  methods: {
  }
};
</script>